const getGroups = (arr) => {
  const list = [];
  arr.forEach((el) => {
    if (el.classType === 'group') {
      list.push({ techId: el.techId, name: el.settings.find((s) => s.techName === 'name').value });
      [].push.apply(list, getGroups(el.childs));
    }
  });
  return list;
};

export default getGroups;
