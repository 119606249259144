<template>
  <div class="setting-item s-scale-options">
    <h3 class="s-scale-options__title">
      Значения и связанные группы
    </h3>
    <draggable v-model="localSetting.values" @sort="updateSortOrder">
      <div
        v-for="el in localSetting.values"
        :key="el.techId"
        class="s-scale-options__item"
      >
        <div :class="['s-scale-options__item-value', { 's-scale-options__item-value--has-groups': hasGroups(el) }]">
          <esmp-input
            :ref="`input_${el.techId}`"
            class="s-scale-options__field"
            label="Название"
            v-model="el.name"
          />
          <esmp-input
            class="s-scale-options__field"
            label="Значение"
            v-model="el.extendedValues[0].value"
          />
          <div
            v-if="isGroupsOpen(el.techId)"
            class="s-scale-options__connected-groups"
          >
            <esmp-checkbox-group
              :key="`${el.techId}_check`"
              v-model="el.connectedGroups"
              class="s-scale-options__connected-groups__item"
            >
              <esmp-checkbox
                v-for="group in groupList"
                :key="group.techId"
                :label="group.techId"
              >
                {{ group.name }}
              </esmp-checkbox>
            </esmp-checkbox-group>
          </div>
        </div>
        <div
          class="s-scale-options__item-actions"
        >
          <span
            v-if="localSetting.values.length > 1"
            @click="remove(el.techId)"
            class="s-scale-options__item-actions__item"
          >
            <esmp-icon name="trash" />
          </span>
        </div>
      </div>
    </draggable>
    <div class="s-scale-options__actions">
      <esmp-button
        class="s-scale-options__actions-item"
        size="small"
        @click="add"
      >
        Добавить
      </esmp-button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import getGroups from '@/components/service-form/helpers/getGroups';

export default {
  name: 'SScaleOptions',
  inject: ['globalState'],
  model: {
    prop: 'setting',
    event: 'input',
  },
  components: { draggable },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openGroupsList: [],
    };
  },
  computed: {
    groupList() {
      return getGroups(this.globalState.elements);
    },
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    lastOrder() {
      return this.localSetting?.values?.length
        ? Math.max(...this.localSetting.values.map((el) => Number(el.sortOrder)))
        : 0;
    },
  },
  methods: {
    uid() {
      return Number(window.HM_CURRENT_UNIQID());
    },
    add() {
      const id = this.uid();
      this.localSetting.values.push({
        id,
        techId: id,
        name: '',
        extendedValues: [
          {
            value: '',
            type: 'value',
          },
        ],
        sortOrder: this.lastOrder + 1,
        connectedGroups: [],
      });
      this.$nextTick(() => {
        this.$refs[`input_${id}`][0].focus();
      });
    },
    remove(id) {
      this.localSetting.values = this.localSetting.values.filter((el) => el.techId !== id);
      this.updateSortOrder();
    },
    updateSortOrder() {
      this.localSetting.values = this.localSetting.values.map((el, i) => ({
        ...el,
        sortOrder: i + 1,
      }));
    },
    // для будущей привязки групп
    toggleGroupsList(id) {
      if (!this.groupList?.length) return;

      if (this.openGroupsList.includes(id)) {
        this.openGroupsList = this.openGroupsList.filter((i) => i !== id);
      } else {
        this.openGroupsList.push(id);
      }
    },
    isGroupsOpen(id) {
      return this.openGroupsList.includes(id);
    },
    hasGroups(element) {
      return !!element.connectedGroups?.length;
    },
    setOpenGroupsList(values) {
      values.forEach((value) => {
        if (value.connectedGroups) {
          this.openGroupsList.push(value.id);
        }
      });
    },
  },
  mounted() {
    this.setOpenGroupsList(this.localSetting.values);
  },
};
</script>
<style lang="scss" scoped>
$gap: 10px;

.s-scale-options {
  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: $gap;
    &-value {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      ::v-deep .esmp-input .esmp-input-icon {
        color: $color-grayscale-60;
      }
      &--has-groups {
        ::v-deep .esmp-input .esmp-input-icon {
          color: $color-primary-1-day;
        }
      }
    }
    &-actions {
      flex-shrink: 0;
      margin-top: $gap;
      margin-left: $gap;
    }
  }

  &__field {
    flex-shrink: 0;
    width: calc(50% - $gap / 2);
    & + & {
      margin-left: $gap;
    }
  }

  &__connected-groups {
    width: 100%;
    margin-bottom: $gap * 2;
    &__item {
      margin-top: 8px;
      ::v-deep .esmp-checkbox-group-item {
        margin-top: 4px;
      }
    }
  }

  &__actions {
    margin-top: $gap * 2;
    &-loader {
      display: inline-block;
    }
    &-item + &-item {
      margin-top: $gap;
    }
  }
}

</style>
